import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import axiosConfig from '../axiosConfig';
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";
import Cookies from 'js-cookie';


const UserProfileEdit = () => {

  const isMountedRef = useRef(false);
  const imageBaseUrl = `${network.serverip}/images/`;
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isFocused, setIsFocused] = useState('');

  const gContext = useContext(GlobalContext);
  const { user } = useContext(GlobalContext);
  const { profile } = useContext(GlobalContext);

  const [formData, setFormData] = useState({
    user_id: '',
    name: '',
    about_me: '',
    registration_number: '',
    state_of_registration: '',
    gender: '',
    street_address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
  });

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCancel = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      user_id: '',
      name: '',
      about_me: '',
      registration_number: '',
      state_of_registration: '',
      gender: '',
      street_address: '',
      city: '',
      state: '',
      country: '',
      pin_code: '',
    }));

    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }

  };


  const handleProfileSubmit = async (e) => {
    e.preventDefault();

    try {

      setErrors({});

      if (!user || !user.id) {
        gContext.toggleSignInModal();
        return;
      }

      if (formData.user_id) {

        const response = await axios.post(`${network.serverip}/api/user-profile-edit-web`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          });

        console.log('This is the response:', response);

        if (response.data.success) {

          const { user, profile } = response.data;

          // Retrieve existing cookie data
          const existingUser = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {};
          const existingProfile = Cookies.get('profile') ? JSON.parse(Cookies.get('profile')) : {};

          // Merge new data with existing data
          const updatedUser = { ...existingUser, ...user };
          const updatedProfile = { ...existingProfile, ...profile };

          // Update cookies with merged data
          Cookies.set('user', JSON.stringify(updatedUser), { expires: 30 });
          Cookies.set('profile', JSON.stringify(updatedProfile), { expires: 30 });

          gContext.setUser(updatedUser);
          gContext.setProfile(updatedProfile);

          if (isMountedRef.current) {
            // Scroll to the top of the screen
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });

            handleSuccessMessage(response.data.message);
          }

          console.log('User profile updated successfully');

        } else {
          console.log('Failed to update user profile');
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          profile_edit_error: 'Something went wrong, please try again',
        }));
      }
    } catch (error) {
      console.error('Error updating profile:', error);

      if (error.response) {

        if (error.response.data.errors) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            profile_edit_error: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          profile_edit_error: 'Something went wrong, please try again',
        }));
      }
    }
  };

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (user && isMountedRef.current) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user_id: user?.id || '',
        name: user?.name || '',
      }));
    }
  }, [user]);

  useEffect(() => {
    if (profile && isMountedRef.current) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        about_me: profile?.about_me || '',
        registration_number: profile?.registration_number || '',
        state_of_registration: profile?.state_of_registration || '',
        gender: profile?.gender || '',
        street_address: profile?.street_address || '',
        city: profile?.city || '',
        state: profile?.state || '',
        country: profile?.country || '',
        pin_code: profile?.pin_code || '',
      }));
    }
  }, [profile]);

  const styles = UserProfileEditStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-1 pt-32 pt-lg-27 pb-xl-25 pb-12">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 mt-4 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }}
                    className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>

              <div className="col-xl-12 col-lg-12 mb-5">
                <div className="bg-white rounded-4 border border-mercury shadow-9">
                  <div>
                    {/* <div className="w-100 d-flex justify-content-center mt-3">
                      <p className="font-size-4 text-success min-height-40">
                        {successMessage || ''}
                      </p>
                    </div> */}
                    <div className="pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts">

                      <div className="form-group">
                        <label
                          htmlFor="mobile_number"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'Mobile number:'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile number"
                          id="mobile_number"
                          name="mobile_number"
                          value={user?.mobile_number || ''}
                          aria-label={`Mobile Number: ${user?.mobile_number || 'N/A'}`}
                          title={`Mobile Number: ${user?.mobile_number || 'N/A'}`}
                          readOnly
                        />
                      </div>


                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          Email:
                        </label>
                        <div className="d-flex align-items-center">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            id="email"
                            name="email"
                            value={user?.email || ''}
                            aria-label={`Email: ${user?.email || 'N/A'}`}
                            title={`Email: ${user?.email || 'N/A'}`}
                            readOnly
                          />
                          <button
                            type="button"
                            className="btn btn-primary ml-2"
                            style={{ padding: '1.4rem 0rem' }}
                            onClick={() => { gContext.toggleEmailUpdateModal() }}
                          >
                            Change
                          </button>
                        </div>
                      </div>

                    </div>

                    {/* <div className="pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                          <div className="form-group d-flex justify-content-end mt-3">
                            <button type="button"
                              onClick={handleCancel}
                              className="btn btn-outline-primary mr-2">
                              Cancel
                            </button>
                            <button type="submit"
                              className="btn btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          {errors?.profile_edit_error ? <p className="font-size-3 text-danger">
                            {errors.profile_edit_error}
                          </p> : null}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="col-xl-12 col-lg-12">
                <div className="bg-white rounded-4 border border-mercury shadow-9">

                  <form onSubmit={handleProfileSubmit}>
                    <div className="w-100 d-flex justify-content-center mt-3">
                      <p className="font-size-4 text-success min-height-40">
                        {successMessage || ''}
                      </p>
                    </div>
                    <div className="pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                      <div className="form-group">
                        <label
                          htmlFor="name"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'Name:'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your name"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          onFocus={() => setIsFocused('name')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'name' ? styles.focussdedInput : styles.inputStyle,
                          }}
                          maxLength={255}
                        />
                        {errors?.name ? <p className="font-size-3 text-danger">
                          {errors.name}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="about_me"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'About me:'}
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Enter about you"
                          id="about_me"
                          name="about_me"
                          value={formData.about_me}
                          onChange={handleChange}
                          rows="4"
                          onFocus={() => setIsFocused('about_me')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'about_me' ? styles.focussdedInput : styles.inputStyle,
                            overflow: 'auto',
                            resize: 'none'
                          }}
                          maxLength={1000}
                        />
                        <p
                          className="font-size-4 text-dodger d-flex justify-content-end"
                        >
                          {`${formData.about_me?.length || 0}/${1000}`}
                        </p>
                        {errors?.about_me ? <p className="font-size-3 text-danger">
                          {errors.about_me}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="registration_number"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'Registration number:'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter registration number"
                          id="registration_number"
                          name="registration_number"
                          value={formData.registration_number}
                          onChange={handleChange}
                          onFocus={() => setIsFocused('registration_number')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'registration_number' ? styles.focussdedInput : styles.inputStyle,
                          }}
                          maxLength={255}
                        />
                        {errors?.registration_number ? <p className="font-size-3 text-danger">
                          {errors.registration_number}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="state_of_registration"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'State of registration:'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter state of registration"
                          id="state_of_registration"
                          name="state_of_registration"
                          value={formData.state_of_registration}
                          onChange={handleChange}
                          onFocus={() => setIsFocused('state_of_registration')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'state_of_registration' ? styles.focussdedInput : styles.inputStyle,
                          }}
                          maxLength={255}
                        />
                        {errors?.state_of_registration ? <p className="font-size-3 text-danger">
                          {errors.state_of_registration}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <p className="font-size-4 text-black-2 font-weight-semibold line-height-reset mt-3">
                          Gender:
                        </p>
                        <div className="d-flex align-items-center mt-2">
                          <div className="form-check mr-5">
                            <input
                              type="radio"
                              id="gender-male"  // Unique ID
                              name="gender"
                              value="Male"
                              className="form-check-input"
                              checked={formData.gender === 'Male'}
                              onChange={handleChange}
                            />
                            <label htmlFor="gender-male" className="form-check-label font-size-4 text-black-2">
                              Male
                            </label>
                          </div>
                          <div className="form-check mr-5">
                            <input
                              type="radio"
                              id="gender-female"  // Unique ID
                              name="gender"
                              value="Female"
                              className="form-check-input"
                              checked={formData.gender === 'Female'}
                              onChange={handleChange}
                            />
                            <label htmlFor="gender-female" className="form-check-label font-size-4 text-black-2">
                              Female
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              id="gender-others"  // Unique ID
                              name="gender"
                              value="Others"
                              className="form-check-input"
                              checked={formData.gender === 'Others'}
                              onChange={handleChange}
                            />
                            <label htmlFor="gender-others" className="form-check-label font-size-4 text-black-2">
                              Others
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="d-flex align-items-center" style={{ marginTop: '3rem', marginBottom: '1rem' }}>
                        <div class="flex-grow-1 border-bottom"></div>
                        <span class="px-3 font-size-4 text-black-2 font-weight-semibold line-height-reset">
                          Address
                        </span>
                        <div class="flex-grow-1 border-bottom"></div>
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="street_address"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'Street address:'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter street address"
                          id="street_address"
                          name="street_address"
                          value={formData.street_address}
                          onChange={handleChange}
                          onFocus={() => setIsFocused('street_address')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'street_address' ? styles.focussdedInput : styles.inputStyle,
                          }}
                          maxLength={255}
                        />
                        {errors?.street_address ? <p className="font-size-3 text-danger">
                          {errors.street_address}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="city"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'City:'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter city"
                          id="city"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          onFocus={() => setIsFocused('city')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'city' ? styles.focussdedInput : styles.inputStyle,
                          }}
                          maxLength={255}
                        />
                        {errors?.city ? <p className="font-size-3 text-danger">
                          {errors.city}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="state"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'State:'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter state"
                          id="state"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          onFocus={() => setIsFocused('state')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'state' ? styles.focussdedInput : styles.inputStyle,
                          }}
                          maxLength={255}
                        />
                        {errors?.state ? <p className="font-size-3 text-danger">
                          {errors.state}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="country"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'Country:'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter country"
                          id="country"
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          onFocus={() => setIsFocused('country')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'country' ? styles.focussdedInput : styles.inputStyle,
                          }}
                          maxLength={255}
                        />
                        {errors?.country ? <p className="font-size-3 text-danger">
                          {errors.country}
                        </p> : null}
                      </div>

                      <div className="form-group">
                        <label
                          htmlFor="pin_code"
                          className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                        >
                          {'Pin code:'}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter pin code"
                          id="pin_code"
                          name="pin_code"
                          value={formData.pin_code}
                          onChange={handleChange}
                          onFocus={() => setIsFocused('pin_code')}
                          onBlur={() => setIsFocused('')}
                          style={{
                            ...isFocused === 'pin_code' ? styles.focussdedInput : styles.inputStyle,
                          }}
                          maxLength={255}
                        />
                        {errors?.pin_code ? <p className="font-size-3 text-danger">
                          {errors.pin_code}
                        </p> : null}
                      </div>

                    </div>

                    <div className="pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                          <div className="form-group d-flex justify-content-end mt-3">
                            <button type="button"
                              onClick={handleCancel}
                              className="btn btn-outline-primary mr-2">
                              Cancel
                            </button>
                            <button type="submit"
                              className="btn btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                          {errors?.profile_edit_error ? <p className="font-size-3 text-danger">
                            {errors.profile_edit_error}
                          </p> : null}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default UserProfileEdit;

function UserProfileEditStyling() {
  const styles = {

    focussdedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    },

    inputStyle: {
      color: 'black'
    }

  };

  return styles;
}

